<template>
  <!-- 列表按钮栏 -->
  <div class="tableFunc">
    <slot name="tableFunc_list"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.tableFunc {
  padding: 10px 0;
}
</style>
