<template>
	<div id="editor"></div>
</template>

<script>
import Wangeditor from "wangeditor";
import utils from "@/utils/utils.js";
import service from "@/api/resource.js";
export default {
	props: {
		ditorHtml: {
			type: String,
			default: "",
		},
		imgType: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			ditor: "",
			content: "",
		};
	},
	mounted() {
		this.createDitor();
	},
	methods: {
		// 初始化ditor
		createDitor() {
			let that = this;
			// 初始化富文本
			that.ditor = new Wangeditor("#editor");
			// 配置 onchange 回调函数
			that.ditor.config.onchange = function(newHtml) {
				that.$emit("updateHtml", newHtml);
			};
			that.ditor.config.menus = [
				"head",
				"bold",
				"fontSize",
				"fontName",
				"italic",
				"underline",
				"strikeThrough",
				"indent",
				"lineHeight",
				"foreColor",
				"backColor",
				"link",
				"list",
				"todo",
				"justify",
				"quote",
				"emoticon",
				"image",
				"undo",
				"redo",
			];
			// 设置编辑区域高度为 440px
			that.ditor.config.height = 440;
			that.ditor.config.zIndex = 100;

			that.ditor.config.customUploadImg = (resultFiles, insertImgFn) => {
				const formData = new FormData();
				formData.append("files", resultFiles[0]);
				formData.append(
					"name",
					utils.UUID(
						JSON.parse(localStorage.getItem("userInfo")).companyId + "_"
					)
				);
				formData.append(
					"companyId",
					JSON.parse(localStorage.getItem("userInfo")).companyId
				);
				formData.append("path", this.imgType);
				service
					.uploadPic(formData)
					.then((res) => {
						const pic = res.data.data;
						pic.forEach((item) => {
							item.url = "//test.gudemanage.com/" + item.url;
						});
						insertImgFn(pic[0].url);
					})
					.catch((error) => {
						that.$message.error(error);
					});
			};
			// // 设置上传图片接口
			// that.ditor.config.uploadImgServer =
			// 	"http://website.gudemanage.com/uploadPic";
			// that.ditor.config.uploadImgHooks = {
			// 	customInsert: function(insertImgFn, result) {
			// 		// result 即服务端返回的接口
			// 		let pic = result.data;
			// 		pic.forEach((item) => {
			// 			item.url = "http://test.gudemanage.com/" + item.url;
			// 		});
			// 		insertImgFn(pic[0].url);
			// 	},
			// };
			// that.ditor.config.uploadImgParams = {
			// 	name: utils.UUID(
			// 		JSON.parse(localStorage.getItem("userInfo")).companyId + "_"
			// 	),
			// 	companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
			// 	path: this.imgType,
			// };
			// that.ditor.config.uploadImgHeaders = {
			// 	Accept: "application/json",
			// 	authorization: sessionStorage.getItem("authorization"),
			// };
			that.ditor.create();
			that.ditor.txt.html(this.ditorHtml);
		},
	},
	beforeDestroy() {
		// 销毁编辑器
		this.ditor.destroy();
		this.ditor = null;
	},
};
</script>

<style scoped>
#editor {
	text-align: left;
	margin-bottom: 10px;
}
</style>
