<template>
  <draggable
    element="ul"
    :list="list"
    :options="{ group: 'title', animation: 150 }"
    :no-transition-on-drag="true"
  >
    <transition-group type="transition" :css="true">
      <slot name="drag_list"></slot>
    </transition-group>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  components: {
    draggable,
  },
};
</script>

<style></style>
